<template>
  <div>
    <b-modal id="modal-inventory" size="xl" title="Pilih Inventori" hide-footer>
      <Table purpose="modal" @chosenItem="chosenItem" />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/inventories/Table.vue";

export default {
  components: {
    Table,
  },
  methods: {
    chosenItem(value) {
      this.$emit("chosenItem", value);
    },
  },
};
</script>

<style>
</style>