<template>
  <div>
    <!-- Header Alert -->

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.inventory_category_id"
                    :multiple="false"
                    :options="inventory_categories"
                    @input="filterByInventoryCategory"
                  />
                </b-input-group>
              </div>
              <div class="col-md-4">
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Saring Nama"
                  ></b-form-input>
                  <template #append>
                    <b-button squared @click="filterByName" variant="success"
                      >Cari</b-button
                    >
                    <b-button squared @click="resetFilter" variant="danger"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>

              <template #cell(photo)="data">
                <div class="image-input-wrapper">
                  <img class="image" :src="data.item.photo" />
                </div>
              </template>

              <template #cell(actions)="data">
                <template v-if="purpose == 'modal' && secondPurpose == 'modal'">
                  <b-button
                    size="sm"
                    class=""
                    variant="primary"
                    @click="
                      chosenItem({
                        id: data.item.id,
                        name: data.item.name,
                      })
                    "
                  >
                    Pilih
                  </b-button>
                </template>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "List",
  props: {
    purpose: String,
    secondPurpose: {
      type: String,
      default: "modal",
    },
  },
  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        inventory_category_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "inventory_category_name",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "inventory_remaining_stock",
          label: "Stok Sisa",
          sortable: true,
        },
        {
          key: "uom_name",
          label: "Satuan",
          sortable: true,
        },
        {
          key: "description",
          label: "keterangan",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      inventory_categories: [],
      // user access
      btn: true,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&inventory_category_id=${this.filter.inventory_category_id}`;
      let response = await module.paginate(
        "inventories",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getInventoryCategoryOption() {
      let response = await module.setTreeSelect("inventory-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.inventory_categories = response.data;
        this.inventory_categories.unshift({
          label: "Saring Berdasar Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      this.pagination();
    },

    async filterByInventoryCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.inventory_category_id = "";
      }
      this.pagination();
    },

    resetFilter() {
      this.filter.inventory_category_id = "";
      this.filter.name = "";
      this.pagination();
    },

    chosenItem(data) {
      this.$emit("chosenItem", data);
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("inventories/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1107") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inventori" }]);
    // Get Data
    this.pagination();
    this.setActiveButton();
    this.getInventoryCategoryOption();
  },
};
</script>
<style scoped>
.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>