<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <b-form-group
        label="Jenis Stok"
        v-slot="{ ariaDescribedby }"
        v-if="purpose == 'add'"
      >
        <b-form-radio
          v-model="form.stock_in_out"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="0"
          >Stok Masuk</b-form-radio
        >
        <b-form-radio
          v-model="form.stock_in_out"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="1"
          >Stok Keluar</b-form-radio
        >
        <small class="text-danger">{{ error.stock_in_out }}</small>
      </b-form-group>

      <b-form-group
        v-if="purpose == 'edit'"
        id="input-group-stock-in-out"
        label="Kuantitas:"
        label-for="input-stock-in-out"
      >
        <b-form-input
          id="input-stock-in-out"
          v-model="form.stock_in_out_show"
          placeholder="Kuantitas"
          disabled
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-trigger-modal-inventory"
        label="Inventori:"
        label-for="input-trigger-modal-inventory"
      >
        <b-form-input
          id="input-trigger-modal-inventory"
          v-model="display.inventory_name"
          placeholder="Pilih Inventori"
          :readonly="purpose == 'add' ? true : false"
          :disabled="purpose == 'add' ? false : true"
          @click="purpose == 'add' ? $bvModal.show('modal-inventory') : ''"
        >
        </b-form-input>
        <small class="text-danger">{{ error.inventory_id }}</small>
      </b-form-group>

      <!-- Input Quantity -->
      <b-form-group
        id="input-group-quantity"
        label="Kuantitas:"
        label-for="input-quantity"
      >
        <b-form-input
          id="input-quantity"
          v-model="form.quantity"
          placeholder="Kuantitas"
          :disabled="purpose == 'add' ? false : true"
        >
        </b-form-input>
        <small class="text-danger">{{ error.quantity }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description"
          >Keterangan: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-description"
          v-model="form.notes"
          placeholder="Keterangan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.notes }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/inventory-stocks/list')"
      >
        Batal
      </b-button>
    </b-form>
    <ModalInventory @chosenItem="chosenItem" />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalInventory from "@/view/components/general/ModalInventory.vue";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    ModalInventory,
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        description: "",
        stock_in_out: "",
        inventory_id: "",
      },
      display: {
        inventory_name: "",
      },
    };
  },

  methods: {
    chosenItem(value) {
      this.form.inventory_id = value.id;
      this.display.inventory_name = value.name;
      this.$bvModal.hide("modal-inventory");
    },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/inventory-stocks/list");
      }
    },
  },
  watch: {
    "form.inventory_name": function (newVal, oldVal) {
      this.display.inventory_name = newVal;
    },
  },
};
</script>

<style>
</style>